// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-classes-tsx": () => import("../src/pages/classes.tsx" /* webpackChunkName: "component---src-pages-classes-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-tsx": () => import("../src/pages/info.tsx" /* webpackChunkName: "component---src-pages-info-tsx" */)
}

